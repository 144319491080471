<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Verify Email" :showBack="true"></topbar>

      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="pad-t-14">
              <label
                >An email has been sent to your rescue email address, <a href="mailto:chansiuming@gmail.com">chansiuming@gmail.com</a>. Follow the directions in the email to verify your email.</label
              >
            </div>
            <div class="pad-t-90">
              <ion-button expand="block">Confirm</ion-button>
            </div>
            <div class="btn-plain btn-resend margin-t-30">
              <span>Resend</span>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
export default {
  name: "verifyEmail",
  components: { IonContent, IonPage, IonButton, topbar },
  data() {
    return {
      isEmail: false,
      fkValue: "",
    };
  },
  ionViewWillEnter() {
    this.$store.state.exitApp = false;
    this.sendGAPageView("Verify Email");
  },
};
</script>
